import * as React from 'react';
import Autocomplete from './Autocomplete';
import {DiagnosesService} from '../services/DiagnosesService';


interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    highlight?: boolean;
    icdVersion: string;
    submitForm: Function;
}

const DiagnoseAutocomplete: React.FunctionComponent<Props> = props => {

    return (
        <Autocomplete {...props} service={DiagnosesService.create(props.icdVersion)}/>
    );
};

export default DiagnoseAutocomplete;
