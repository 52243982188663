import {http} from './api';

export const GroupService = {
  create: (patientCaseJSON, patientCaseURL, systemId, locale, preReleaseToken, cancelToken) =>
      http.post(`patient_cases/group`, patientCaseJSON, {
    params: {
      locale: locale,
      pc: patientCaseURL,
      system_id: systemId,
      pre_release_token: preReleaseToken
    },
    cancelToken: cancelToken
  }),
};
