import {create} from "apisauce";

export const http = create({
    headers: {Accept: 'application/json'},
});

export const TarposService = {
    create: (url) => ({
        get: (term) => http.get(url,
            {
                search: term,
                terminal_only: 1,
                max_results: 15,
                highlight: 1
            }),
        url: url
    })
}

