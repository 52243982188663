import * as React from 'react';
import { i18n } from "../config/i18n";

interface Props {
    isSubmitting: boolean;
    reset: Function;
}

const FormButtons: React.FunctionComponent<Props> = ({ isSubmitting, reset }) => {
    return (
        <div className="text-start">
            <button
                onClick={() => reset()}
                type="button"
                disabled={isSubmitting}
                className="btn btn-secondary me-2"
            >
                {i18n.t("reset_case")}
            </button>
            <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
            >
                {i18n.t('group')}
            </button>

        </div>
    )
};

export default FormButtons
