import * as React from 'react';
import {FieldArray} from 'formik';
import GtinDrug from './GtinDrug';
import { i18n } from "../../config/i18n";
import * as isEqual from "react-fast-compare";
import {ITarpo} from "../../interfaces";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface Props {
    drugs: ITarpo[];
    setFieldValue: Function;
    errors: any;
    submitForm: Function;
    mainDiagnosisUsed: boolean;
}

class Drugs extends React.Component<Props> {

    shouldComponentUpdate(nextProps: Props) {
        const {drugs, errors} = this.props;

        return (
            !isEqual(drugs, nextProps.drugs) ||
            !isEqual(errors.drugs, nextProps.errors.drugs));
    }

    render() {
        const {drugs, setFieldValue, submitForm, mainDiagnosisUsed} = this.props;
        const onDragEnd = (result, move) => {
            const { source, destination } = result;
            if (!destination) return;
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            )
                return;

            // move code to destination
            move(source.index, destination.index);
            submitForm();
        };


        return (
            <div className="codes_box">
                <div className="row">
                    <div className="col-sm-12">
                        <h4>
                            {i18n.t("drugs")}
                            <span className="ms-2 text-secondary" style={{fontSize: 10}}> {i18n.t('drugs_explanation')}</span>
                        </h4>
                    </div>
                </div>

                <div>
                    <div className="code_input_div row">
                        <div className="col-lg-6 pe-0 text-secondary" style={{fontSize: 8}}>
                            <div className="input-group">
                                <div className="col-sm-6 px-0">
                                    GTIN-Code
                                </div>
                                <div className="col-sm-2 px-0">
                                    {i18n.t('tarpo_number')}
                                </div>
                                <div className="col-sm-3 px-0">
                                    {i18n.t('amount')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <FieldArray
                        name="tarpos"
                        render={arrayHelpers => (
                            <DragDropContext onDragEnd={result => {onDragEnd(result, arrayHelpers.move)}}>
                                <Droppable droppableId="tarpo-dropzone">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {drugs && drugs.map((tarpo, index) => (
                                                ('402' == tarpo.tariff) &&
                                                <Draggable key={index} draggableId={'tarpo' + index} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <GtinDrug
                                                                index={index}
                                                                tarpo={tarpo}
                                                                setFieldValue={setFieldValue}
                                                                submitForm={submitForm}
                                                                key={index}
                                                                arrayHelpers={arrayHelpers}
                                                                mainDiagnosisUsed={mainDiagnosisUsed}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            <div className="row pb-2">
                                                <div className="col-sm-12">
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.push(
                                                            {
                                                                code: "",
                                                                number: 1,
                                                                active: true,
                                                                tariff: '402'
                                                            })}
                                                        className="float-end btn btn-secondary btn-sm pe-2 ps-2"
                                                    >
                                                        <i className="fa fa-plus"/>
                                                    </button>
                                                </div>
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    />
                </div>
            </div>
        )
    }
}

export default Drugs
