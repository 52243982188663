import * as React from 'react';
import TarpoAutocomplete from "../../form-fields/TarpoAutocomplete";
import {Field} from "formik";
import { i18n } from "../../config/i18n";
import {ITarpo} from "../../interfaces";
import {useEffect} from "react";

interface Props {
    index: any;
    tarpo: ITarpo;
    setFieldValue: any;
    submitForm: Function;
    arrayHelpers: any;
    tarmedVersion: string;
    mainDiagnosisUsed: boolean;
    serviceCatalogVersion: string;
}

const Tarpo: React.FunctionComponent<Props> = props => {
    const {index, tarpo, setFieldValue, submitForm, arrayHelpers, tarmedVersion, mainDiagnosisUsed, serviceCatalogVersion} = props;
    const isImplant = tarpo.tariff == '940' && tarpo.code == '41030'
    useEffect(() => {
        if(tarpo.tariff == '940')
            setFieldValue(`tarpos[${index}].code`, '41030')
    }, [tarpo.tariff]);


    return (
        <div key={index}>
            <div className="code_input_div row">
                <div className="col-lg-7 pe-0">
                    <div className={'input-group ' + (!tarpo.active ? 'disabled' : '')}>
                        <div className="col-sm-4 px-0">
                            <div className={"input-group"}>
                                <TarpoAutocomplete
                                    name={`tarpos[${index}].code`}
                                    value={tarpo && tarpo.code || ""}
                                    setValue={(name, value) => {
                                        if (tarpo.code != value)
                                            setFieldValue(`tarpos[${index}].amount`, 0)
                                        setFieldValue(name, value)
                                    }}
                                    valid={tarpo.valid}
                                    used={tarpo.used}
                                    tariff={tarpo.tariff}
                                    tarmedVersion={tarmedVersion}
                                    serviceCatalogVersion={serviceCatalogVersion}
                                    submitForm={submitForm}
                                />
                                <span className="fa fa-check removetag me-0"
                                      title={i18n.t(`${tarpo.active ? 'de' : ''}activate_code`)}
                                      onClick={async () => {
                                          setFieldValue(`tarpos[${index}].active`, !tarpo.active);
                                          await Promise.resolve();
                                          submitForm();
                                      }}/>
                            </div>
                        </div>
                        <div className="col-sm-2 px-0" title={i18n.t('tarpo_number')}>
                            {tarpo.tariff != '940' &&
                                <Field type="float"
                                       name={`tarpos[${index}].number`}
                                       id={`tarpos[${index}].number`}
                                       className={"form-control"}
                                       value={tarpo && tarpo.number || ''}
                                />}
                        </div>
                        <div className="col-sm-3 px-0" title={i18n.t('tariff_code') + ': ' + tarpo.tariff_text}>
                            <Field component="select" name={`tarpos[${index}].tariff`}
                                   className="form-control form-select">
                                {tarmedVersion && <option value="001">001</option>}
                                <option value="003">003</option>
                                <option value="222">222</option>
                                <option value="322">322</option>
                                <option value="940">940</option>
                                {// TODO As soon as an LK version is available for us, we have to check which tariffs are still relevant for grouping.
                                    serviceCatalogVersion && <option value="LKN">LKN</option>}
                            </Field>
                        </div>
                        <div className="col-sm-3 px-0" title={i18n.t('amount')}>
                            { ((tarpo.trigger && !mainDiagnosisUsed) || isImplant) &&
                                <Field type="float"
                                       name={`tarpos[${index}].amount`}
                                       id={`tarpos[${index}].amount`}
                                       className={"form-control"}
                                       value={tarpo && tarpo.amount || ''}
                                />
                            }
                            <span
                                className="fa fa-remove removetag"
                                onClick={async () => {
                                    arrayHelpers.remove(index);
                                    await Promise.resolve();
                                    submitForm();
                                }}
                                title={i18n.t('delete_code')}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 code_description">
                    <span>{tarpo.text}</span>
                </div>
            </div>
        </div>
    )
};

export default Tarpo
