import * as React from 'react';
import {FieldArray} from 'formik';
import Tarpo from './Tarpo';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ITarpo} from "../../interfaces";
import { i18n } from "../../config/i18n";

interface Props {
    tarpos: ITarpo[];
    setFieldValue: any;
    submitForm: any;
    locale: string;
    title: string;
    bgColor?: string;
    id: string;
    tarmedVersion: string;
    mainDiagnosisUsed: boolean;
    serviceCatalogVersion: string;
}

const Tarpos: React.FunctionComponent<Props> = props => {
    const {tarpos, setFieldValue, submitForm, bgColor, title, id, tarmedVersion, mainDiagnosisUsed, serviceCatalogVersion} = props;

    const onDragEnd = (result, move) => {
        const { source, destination } = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        // move code to destination
        move(source.index, destination.index);
        submitForm();
    };

    return (
        <div className="codes_box" id={"tarpos_box_" + id} style={{backgroundColor: bgColor}}>
            <div className="row">
                <div className="col-sm-12">
                    <h4>{title}</h4>
                    <p className="ms-2 text-secondary" style={{fontSize: 10}}>{i18n.t('services_explanation')}</p>
                </div>
            </div>
            <div>
                <div className="code_input_div row">
                    <div className="col-lg-7 pe-0 text-secondary" style={{fontSize: 8}}>
                        <div className="input-group">
                            <div className="col-sm-4 px-0">
                                Code
                            </div>
                            <div className="col-sm-2 px-0">
                                {i18n.t('tarpo_number')}
                            </div>
                            <div className="col-sm-3 px-0">
                                {i18n.t('tariff_code')}
                            </div>
                            <div className="col-sm-3 px-0">
                                {i18n.t('amount')}
                            </div>
                        </div>
                    </div>
                </div>
                <FieldArray
                    name="tarpos"
                    render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => {onDragEnd(result, arrayHelpers.move)}}>
                            <Droppable droppableId="tarpo-dropzone">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {tarpos && tarpos.map((tarpo, index) => (
                                            (tarpo.tariff != '402') &&
                                            <Draggable key={index} draggableId={'tarpo' + index} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Tarpo
                                                            index={index}
                                                            tarpo={tarpo}
                                                            setFieldValue={setFieldValue}
                                                            submitForm={submitForm}
                                                            key={index}
                                                            arrayHelpers={arrayHelpers}
                                                            tarmedVersion={tarmedVersion}
                                                            mainDiagnosisUsed={mainDiagnosisUsed}
                                                            serviceCatalogVersion={serviceCatalogVersion}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>

                                        ))}
                                        <div className="row pb-2">
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.push(
                                                        {
                                                            code: "",
                                                            number: 1,
                                                            active: true,
                                                            tariff: '001'
                                                        })}
                                                    className="float-end btn btn-secondary btn-sm pe-2 ps-2"
                                                >
                                                    <i className="fa fa-plus"/>
                                                </button>
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                />
            </div>
        </div>
    );
};

export default Tarpos
