import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import transformItems from "../utils/dropdown_data";
import {useState, useEffect, useRef} from 'react';

interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    service: any;
    highlight?: boolean;
    submitForm: Function;
}

const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
}

const Autocomplete: React.FunctionComponent<Props> = props => {
    const {value, showValid, valid, used, setValue, name, service, highlight, submitForm} = props;
    const [items, setItems] = useState([]);
    const doSearch = useRef<boolean>(false)
    useDebouncedEffect(() => onSuggestionsFetchRequested(value), [value], 500)

    let inputClass = 'form-control';
    if (valid === false && value.length) {
        inputClass += ' input-invalid';
    } else if (showValid && value.length) {
        inputClass += ' input-valid';
    } else if (used === true && value.length) {
        inputClass += ' input-valid';
    } else if (highlight === true && value.length) {
        inputClass += ' input-highlight';
    }

    const onSuggestionsFetchRequested = (newValue) => {
        // start search only with minimum of 3 characters and if search was initialized
        if(!doSearch.current || newValue.length < 3)
            return
        service.get(newValue).then(response => {
            setItems(response.data);
        })
        doSearch.current = false
    };

    const onSuggestionsClearRequested = () => {
        setItems([])
    };

    return (
        <Autosuggest
            inputProps={{
                className: inputClass,
                // cast to string to avoid errors when switching from integers (e.g. table IDs) to string (e.g. icd code)
                value: String(value || ''),
                onChange: (event, {newValue}) => {
                    setValue(name, newValue);
                }
            }}
            suggestions={transformItems(items)}
            getSuggestionValue={(item) => item.code}
            onSuggestionsFetchRequested={() => {doSearch.current = true;}}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            renderSuggestionsContainer={({containerProps, children}) => (
                <div className="menu" {...containerProps}>
                    {children}
                </div>
            )}
            onSuggestionSelected={(value, {suggestion, suggestionValue}) => {
                setValue(name, suggestionValue);
                setItems([suggestion]);
                submitForm();
            }}
            renderSuggestion={(item, {isHighlighted}) => (
                <div
                    className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                    key={item.code}
                    dangerouslySetInnerHTML={{__html: item.text}}
                />
            )}
        />
    );
};

export default Autocomplete;
