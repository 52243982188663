import * as React from 'react';
import { useState } from 'react';
import { i18n } from "../config/i18n";
import { ToastContainer } from 'react-toastify';
import { useConsumer } from "../hooks/useConsumer"
import Progressbar from "./progress/Progressbar";
import Alert from "./Alert";
import {BatchFile} from "../pages/ImportProgressPage"

interface Props {
    locale: string,
    batchFileId: number,
    batchFile: BatchFile
}

const ImportProgress: React.FC<Props> = ({ locale, batchFileId, batchFile }) => {
    i18n.locale = locale;
    const [data, setData] = useState({...batchFile})

    useConsumer({
            channel: "BatchFileChannel",
            id: batchFileId,
            received: (receivedData) => {
                if ((receivedData.statusType === 'success' || receivedData.statusType === 'danger') &&
                    !(data.statusType === 'success' || data.statusType === 'danger')) {
                    location.reload();
                } else {
                    setData((prevState => {
                            let nextState = Object.assign(
                                {},
                                prevState,
                                receivedData,
                            )
                            if (receivedData.error) {
                                nextState.errors =  prevState.errors + receivedData.error
                            }
                            return nextState
                        }
                    ))
                }
            },

        }
    )
    return (
        <>
            <div id="update_infos">
                <div className="row">
                    <div className="col-lg-12 vertical-spacer">
                        <Alert type={data.statusType} style={{ fontSize: '1.2em' }}>
                            <div dangerouslySetInnerHTML={{__html: data.status}} />
                            {!(data.statusType === 'danger' || data.statusType === 'success') &&
                            <Progressbar
                                total={data.total || 0}
                                current={data.current}
                                unit=""
                                disabled={data.statusType === 'danger' || data.statusType === 'success'}
                            />}
                            <div className="row">
                                <div className="col-lg-3">
                                    {i18n.t('num_cases_group')}
                                </div>
                                <div className="col-lg-4">
                                    {(data.current === -1) ? 0 : data.current}
                                    {" / "}
                                    {(data.cases === -1) ? 0 : data.cases}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    {i18n.t('import_date')}
                                </div>
                                <div className="col-lg-4">
                                    {data.date}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    {i18n.t('format')}
                                </div>
                                <div className="col-lg-4">
                                    {data.format}
                                </div>
                            </div>
                            <a
                                className="btn btn-primary vertical-spacer"
                                rel="nofollow"
                                data-method="delete"
                                href={"/batch_files/" + batchFileId}>
                                {i18n.t('delete')}
                            </a>
                            {!(data.statusType === 'info') &&
                                <>
                                    <a
                                        className={"horizontal-spacer btn btn-primary vertical-spacer" }
                                        rel="nofollow"
                                        href={"/batch_files/" + batchFileId + "/download_result"}>
                                        Download
                                    </a>
                                    { data.format == 'data_collection_2022' &&
                                        <a
                                        className={"horizontal-spacer btn btn-outline-secondary vertical-spacer" }
                                        rel="nofollow"
                                        href={"/batch_files/" + batchFileId + "/download_result?get_input_file=1"}>
                                            {i18n.t('download_input_file')}
                                        </a>
                                    }
                                    { data.hasTarmedVolumes &&
                                        <a
                                            className={"btn btn-secondary vertical-spacer"}
                                            rel="nofollow"
                                            href={"/batch_files/" + batchFileId + "/download_result?get_transcoding_output=1"}>
                                            {i18n.t('download_transcoding_output')}
                                        </a>
                                    }
                                </>
                            }
                        </Alert>
                    </div>
                </div>
                {data.errors != '' &&
                <div className="row">
                    <div className="col-lg-12 vertical-spacer">
                        <h4>{i18n.t('import_errors')}</h4>
                    </div>
                    <div className="col-lg-12">
                        <textarea
                            className="form-control"
                            id="log_error"
                            rows={10}
                            value={data.errors}
                            readOnly={true}
                        />
                    </div>
                </div>}
            </div>
            <ToastContainer hideProgressBar={true}/>
        </>
    )
}

export default ImportProgress
