import * as React from 'react';
import Autocomplete from './Autocomplete';
import {TarposService} from '../services/TarposService.js';
import {Field} from "formik";


interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    tariff: string;
    tarmedVersion: string;
    submitForm: Function;
    serviceCatalogVersion: string;
}

const TarpoAutocomplete: React.FunctionComponent<Props> = props => {
    const {tariff, name, value, tarmedVersion, serviceCatalogVersion} = props

    const searchUrls = {
        '001' : ((window as any).tarmedUrl as string) + tarmedVersion + '/search',
        '317' : (window as any).laboratoryAnalysisUrl as string,
        '452' : (window as any).migelUrl as string,
        '454' : (window as any).migelUrl as string,
        '402' : (window as any).drugCodeUrl as string,
        '003' : (window as any).tariff003Url as string,
        'LKN' : ((window as any).serviceCatalogUrl as string) + serviceCatalogVersion + '/search',
        'empty' : ''
    }
    const searchUrl = (searchUrls[tariff || 'empty'] || '').replace(' ', '_')

    return (
        <>
        { searchUrl == '' ?
            <Field type="float"
                   name={name}
                   id={name}
                   className={"form-control"}
                   value={value}
            /> : <Autocomplete {...props} service={TarposService.create(searchUrl)}/>}
        </>
    );
};

export default TarpoAutocomplete;
