import {create} from "apisauce";

export const http = create({
  // eslint-disable-next-line no-undef
  baseURL: (icdsUrl),
  headers: {Accept: 'application/json'},
});

export const DiagnosesService = {
    create: (icdVersion) => {
        const url = icdsUrl + icdVersion + '/search'
        return {
            get: (term) => http.get(url,
                {
                    search: term,
                    terminal_only: 1,
                    max_results: 15,
                    highlight: 1
                }),
        }
    }
};
