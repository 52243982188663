import * as React from 'react';
import {Field, FormikValues} from 'formik';
import { i18n } from "../../config/i18n";
import InputField from "../../form-fields/InputField";
import SelectField from "../../form-fields/SelectField";
import {IDemographicFlags} from "../../interfaces";

interface Props {
    setFieldValue: Function,
    submitForm: Function,
    values: FormikValues,
    flags: IDemographicFlags,
    displayCodingState?: boolean,
    originalAge?: number,
}

class AdministrativeData extends React.Component<Props> {

    constructor(props) {
        super(props);

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    async handleSelectChange(name, value) {
        const {setFieldValue, submitForm} = this.props;
        setFieldValue(name, value);
        await Promise.resolve();
        submitForm();
    }

    render() {
        const {values, setFieldValue, flags, originalAge, submitForm} = this.props;
        const isChecked = (radioValue, storedValue) =>
            radioValue === storedValue;

        return (
            <div className="codes_box adm_box" id="adm_box">
                <div className="row">
                    <div className="col-sm-12">
                        <h4>{
                            i18n.t("patient_contact")}
                            <span className="ms-2 text-secondary" style={{fontSize: 10}}> {i18n.t('adm_vars')}</span>
                        </h4>
                    </div>
                    <div className="col-sm-6 px-0">
                        <SelectField
                            name="sex"
                            // no flags at the moment in outpatient
                            grid={{label: 6, input: 6}}
                            options={[
                                {label: i18n.t("unknown"), value: "U"},
                                {label: i18n.t("male"), value: "M"},
                                {label: i18n.t("female"), value: "W"},
                            ]}
                            onChange={(e) => this.handleSelectChange('sex', e.target.value)}

                        />
                    </div>

                    <div className="col-sm-6 px-0">
                        {/*<div className="row pb-2">
                            {[{value: "1", label: i18n.t("age_years")}, {
                                value: "0",
                                label: i18n.t("age_days")
                            }].map((option, index) => (
                                <div className="col-sm-6 pe-0" key={option.value}>
                                    <label key={index} className="my-2 mt-8 cursor-pointer">
                                        <Field name="age_selection">
                                            {({field}) => (
                                                <input
                                                    {...field}
                                                    type="radio"
                                                    name={field.name}
                                                    checked={isChecked(option.value, field.value)}
                                                    onChange={() => {
                                                        field.onChange(field.name)(option.value);
                                                        if (option.value === "1") {
                                                            setFieldValue("age_years", originalAge);
                                                            setFieldValue("age_days", 0);
                                                        } else {
                                                            setFieldValue("age_years", 0);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <span className="ps-2">{option.label}</span>
                                    </label>
                                </div>

                            ))}
                        </div>*/}

                        <InputField
                            name="age_years"
                            // no flags at the moment in outpatient
                            disabled={values['age_selection'] === '0'}
                            grid={{label: 7, input: 5}}
                        />
{/*

                        <InputField
                            name="age_days"
                            // no flags at the moment in outpatient
                            disabled={values['age_selection'] !== '0'}
                            grid={{label: 7, input: 5}}
                        />
*/}

                    </div>
                </div>
            </div>
        );
    }
}

export default AdministrativeData
