import * as React from 'react';
import TarpoAutocomplete from "../../form-fields/TarpoAutocomplete";
import {Field} from "formik";
import { i18n } from "../../config/i18n";
import {ITarpo} from "../../interfaces";

interface Props {
    index: any;
    tarpo: ITarpo;
    setFieldValue: any;
    submitForm: any;
    arrayHelpers: any;
    mainDiagnosisUsed: boolean;
}

const GtinDrug: React.FunctionComponent<Props> = props => {
    const {index, tarpo, setFieldValue, submitForm, arrayHelpers, mainDiagnosisUsed} = props;

    return (
        <div key={index}>
            <div className="code_input_div row">
                <div className="col-lg-6 pe-0">
                    <div className={'input-group ' + (!tarpo.active ? 'disabled' : '')}>
                        <div className="col-sm-6 px-0">
                            <div className={"input-group"}>
                                <TarpoAutocomplete
                                    name={`tarpos[${index}].code`}
                                    value={tarpo && tarpo.code || ""}
                                    setValue={setFieldValue}
                                    valid={tarpo.valid}
                                    used={tarpo.used}
                                    tariff={'402'}
                                    tarmedVersion={''}
                                    serviceCatalogVersion={''}
                                    submitForm={submitForm}
                                />
                                <span className="fa fa-check removetag me-0"
                                      title={i18n.t(`${tarpo.active ? 'de' : ''}activate_code`)}
                                      onClick={async () => {
                                          setFieldValue(`tarpos[${index}].active`, !tarpo.active);
                                          await Promise.resolve();
                                          submitForm();
                                      }}/>
                            </div>
                        </div>
                        <div className="col-sm-2 px-0" title={i18n.t('tarpo_number')}>
                            <Field type="float"
                                   name={`tarpos[${index}].number`}
                                   id={`tarpos[${index}].number`}
                                   className={"form-control"}
                                   value={tarpo && tarpo.number || 1}
                            />
                        </div>
                        <div hidden={true} title={i18n.t('tariff_code') + ': ' + tarpo.tariff_text}>
                            <Field type="text"
                                   name={`tarpos[${index}].tariff`}
                                   id={`tarpos[${index}].tariff`}
                                   className={`form-control${tarpo.tariff_text === 'invalid_tariff' ? ' input-invalid' : ''}`}
                                   value={'402'}
                            />
                        </div>
                        <div className="col-sm-3 px-0" title={i18n.t('amount')}>
                            { tarpo.trigger && !mainDiagnosisUsed &&
                                <Field type="float"
                                       name={`tarpos[${index}].amount`}
                                       id={`tarpos[${index}].amount`}
                                       className={"form-control"}
                                       value={tarpo && tarpo.amount || 0.0}
                                />
                            }
                            <span
                                className="fa fa-remove removetag"
                                onClick={async () => {
                                    arrayHelpers.remove(index);
                                    await Promise.resolve();
                                    submitForm();
                                }}
                                title={i18n.t('delete_code')}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 code_description">
                    <span>{tarpo.text}</span>
                </div>
            </div>
        </div>
    )
};

export default GtinDrug
