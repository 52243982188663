import * as React from 'react';
import {IGrouperResults} from "../../interfaces";
import { i18n } from "../../config/i18n";
import Spinner from "../Spinner";

interface Props {
    grouperResults: IGrouperResults,
    showPath: boolean
}

const GroupResults: React.FunctionComponent<Props> = (
    {
        grouperResults,
        showPath
    }) => {
    return (
        <div className="code_proposal_box" id="group_results_box">
            <div className="row vertical-spacer">
                <div className="col-sm-12">
                    <h4>{i18n.t("group_results")}</h4>
                </div>
                <div className="col-12 p-0">
                    <div className="row">
                        <div className="col-sm-12">
                            {grouperResults ?
                                <>
                                    <div className="code_description" id="amb_group_description">
                                        <h4>
                                            {grouperResults.amb_group.code}
                                        </h4>
                                        <strong>
                                            {grouperResults.amb_group.text}
                                        </strong>
                                    </div>

                                    <table className="grouping-table">
                                        <tbody>
                                        <tr>
                                            <td className="pe-4">
                                                <strong>{i18n.t('ecw')}</strong>
                                            </td>
                                            <td>
                                                {grouperResults.amb_group.cost_weight}
                                            </td>
                                        </tr>
                                        {grouperResults.amb_group.implants_included != null &&
                                            <tr>
                                                <td colSpan={2}>
                                                    {i18n.t(grouperResults.amb_group.implants_included ?
                                                        'implants_included' : 'implants_excluded')}
                                                </td>
                                            </tr>
                                        }
                                        {grouperResults.capitulum &&
                                            <tr>
                                                <td>
                                                    <label>Capitulum</label>
                                                </td>
                                                <td>
                                                    {grouperResults.capitulum.code} - {grouperResults.capitulum.text}
                                                </td>
                                            </tr>
                                        }
                                        { showPath &&
                                        <>
                                        <tr><td colSpan={2}><hr/></td></tr>
                                        <tr>
                                            <td>
                                                <label>{i18n.t('calculated_pdx')}</label>
                                            </td>
                                            <td>
                                                {grouperResults.calculated_pdx}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {grouperResults.message}
                                            </td>
                                        </tr>
                                        { grouperResults && grouperResults.decision_path &&
                                            <tr>
                                                <td><label>Decision Path</label></td>
                                                <td>
                                                    <div className="">
                                                        {grouperResults.decision_path.map((explanation, i) => (
                                                            <div className="alert alert-info" key={i}>
                                                                {explanation.name}
                                                                {explanation.explanation &&
                                                                <div>
                                                                    {explanation.explanation.variables.map((k, i) =>
                                                                        <div key={i} className="badge bg-primary me-1">{k}</div>)
                                                                    }
                                                                    {explanation.explanation.codes.map((k, i) =>
                                                                        <div key={i} className="badge bg-info text-white me-1">{k}</div>)
                                                                    }
                                                                </div>
                                                            }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        </>}
                                        </tbody>
                                    </table>
                                </>
                                : <Spinner/>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default GroupResults
