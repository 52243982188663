import * as React from 'react';
import {FormikValues} from 'formik';
import DiagnoseAutocomplete from "../../form-fields/DiagnoseAutocomplete";
import { i18n } from "../../config/i18n";
import {ISystem} from "../../interfaces";

interface Props {
    setFieldValue: Function,
    values: FormikValues,
    submitForm: Function,
    changeValue: Function,
    system: ISystem
}

const Diagnoses: React.FunctionComponent<Props> = props => {
    const {values, changeValue, setFieldValue, submitForm, system} = props;
    const mainDiagnosis = values.main_diagnosis;
    const mainDiagnosisCode = mainDiagnosis && mainDiagnosis.code;
    const secondaryDiagnoses = values.secondary_diagnoses || [];

    const onDragEnd = (result, move) => {
        const { source, destination } = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        // move code to destination
        move(source.index, destination.index);
        submitForm();
    };

    return (
        <div className="codes_box" id="ddx_box">
            <div className="row vertical-spacer">
                <div className="col-sm-12">
                    <h4>{i18n.t("pdx")}</h4>
                    <h6>{system.catalog_versions.icd}</h6>
                    <p className="ms-2 text-secondary" style={{fontSize: 10}}>{i18n.t('pdx_explanation')}</p>
                </div>
                <div className="col-sm-12 p-0">
                    <div className={'code_input_div row ' + (mainDiagnosis &&  !mainDiagnosis.active ? 'disabled' : '')}>
                        <div className="col-sm-3">
                            <div className="input-group">
                                <DiagnoseAutocomplete
                                    name={`main_diagnosis.code`}
                                    value={mainDiagnosis && mainDiagnosis.code || ""}
                                    valid={mainDiagnosis && mainDiagnosis.valid}
                                    setValue={setFieldValue}
                                    used={mainDiagnosis && mainDiagnosis.used}
                                    icdVersion={system.catalog_versions.icd}
                                    submitForm={submitForm}
                                />
                                {mainDiagnosis &&
                                    <span className="fa fa-check removetag me-3"
                                          title={i18n.t(`${mainDiagnosis.active ? 'de' : ''}activate_code`)}
                                          onClick={async () => {
                                              setFieldValue(`main_diagnosis.active`, !mainDiagnosis.active);
                                              await Promise.resolve();
                                              submitForm();
                                          }}/>
                                }
                                <i className="fa fa-remove removetag" title={i18n.t('delete_code')}
                                   onClick={() => {
                                       changeValue('main_diagnosis', {code: ''});
                                       submitForm();
                                   }}/>
                            </div>
                        </div>
                        {mainDiagnosis &&
                        <>
                            {mainDiagnosis.text &&
                            <div className="col-sm-8 code_description" id="pdxDescription">
                              <a href={mainDiagnosis.url} target="_blank">{mainDiagnosis.text}</a>
                            </div>}
                        </>}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Diagnoses
