import * as React from 'react';
import { i18n } from "../config/i18n";
import {ISystem} from "../interfaces";

interface Props {
    systems: ISystem[],
    currentSystem: ISystem,
    changeSystem: Function
}

const SystemSelection: React.FunctionComponent<Props> = (
    { systems, currentSystem, changeSystem}) => {
    return (
        <div className="codes_box" id="group_resusystem_selection">
            <div className="row">
                <div className="col-sm-3">
                    <h4>{i18n.t("system_selection")}</h4>
                </div>
                <div className="col-sm-9">
                    <select
                        name="system_selection"
                        id="system_selection"
                        className="form-control form-select"
                        onChange={(e) => changeSystem(e.target.value)}
                        value={currentSystem.id}
                    >
                        {systems.map((system => (
                            <option
                                key={system.id}
                                value={system.id}>
                                {system.name}
                            </option>
                        )))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SystemSelection
