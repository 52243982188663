import * as React from 'react';

import SingleCaseGroupForm from '../forms/SingleCaseGroupForm'
import {LocaleProvider} from '../contexts/LocaleContext';
import { i18n } from "../config/i18n";
import {ToastContainer} from 'react-toastify';
import {ISystem} from "../interfaces";
import {BrowserRouter as Router, Route} from 'react-router-dom';

interface Props {
    locale: string,
    systems: ISystem[],
    showPath: boolean
}

declare global {
    interface Window {
        globalTranslations?: any;
    }
}

class SingleCaseGroupPage extends React.Component<Props> {

    static getUrlParam(name: string, url: string): string {
        const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
        if (results == null) {
            return null;
        }
        else {
            return decodeURI(results[1]) || '';
        }
    }

    render() {
        const {systems, locale, showPath} = this.props;

        return (
            <>
                <Router>
                    <Route
                        path='/'
                        render={({location, history}) => {
                            const urlEncodedPc = SingleCaseGroupPage.getUrlParam('pc', location.search);
                            const systemId = SingleCaseGroupPage.getUrlParam('system_id', location.search);
                            const preRelaseToken = SingleCaseGroupPage.getUrlParam('pre_release_token', location.search);
                            i18n.locale = locale;
                            return (<LocaleProvider value={locale}>

                                <SingleCaseGroupForm
                                    urlEncodedPc={urlEncodedPc}
                                    currentSystemId={systemId}
                                    history={history}
                                    locale={locale}
                                    systems={systems}
                                    showPath={showPath}
                                    preReleaseToken={preRelaseToken}
                                />
                            </LocaleProvider>)
                        }}/>
                </Router>
                <ToastContainer hideProgressBar={true}/>
            </>
        )
    }
}

export default SingleCaseGroupPage
