import * as React from 'react';
import classnames from 'classnames';

interface Props {
    total: number,
    current: number,
    unit: string,
    disabled?: boolean
}

const Progressbar: React.FC<Props> = ({ total, current, unit, disabled }) => {
    const percentages = total && current ? current / total * 100 : 100
    const textProgress = () => {
        if (percentages === 0 || disabled || !current) {
            return ''
        }
        if (unit === '%') {
            return `${percentages | 0}%`
        }
        if (total) {
            return `${current}/${total} ${unit}`
        }
    }

    return (
        <div className="progress vertical-spacer" style={{height: '18px'}}>
            <div
                className={classnames('progress-bar', {
                    'bg-primary': current > 0 && !disabled,
                    'progress-bar-striped progress-bar-animated': current < 1 && !disabled,
                    'bg-secondary': current < 1 || disabled,
                })}
                id="group_results_progress"
                role="progressbar"
                style={{ width: `${percentages}%` }}
            >
                {textProgress()}
            </div>
        </div>
    )
}

export default Progressbar
